// import AddRule from '@/layouts/Stack/components/AddRule';
import showErrorModal from '@/mixins/showErrorModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import AddContainer from '@/layouts/Stack/components/AddContainer';

export default {
  mixins: [showErrorModal, storeMixin],
  data() {
    return {
      device_id: '',
      certificatePayload: [],
      timerId: '',
      name: '',
      timerCnt: 0,
      isProcessing: false,
    };
  },
  computed: {},
  methods: {
    addContainer: function (instance) {
      const that = this;
      let self;
      let time;
      let res = '';
      return new Promise(() => {
        const selfName = 'addRule';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          title: this.$t('title.certificate'),
          component: AddContainer,
          closeOnBackdrop: false,
          props: {
            // router: instance.id,
            instance: instance,
          },
          on: {
            change: data => {
              instance = data;
              // console.log(data);
              // Vue.set(self.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(self.footer.confirm.props, 'disabled', true);
            },
            ready: () => {
              Vue.set(self.footer.confirm.props, 'disabled', false);
            },
          },
          onOpen(inst) {
            self = inst;
          },
          footer: {
            confirm: {
              props: { title: this.$t('sure.certificate'), loading: false },
              on: {
                click: () => {
                  self.text = null;
                  let mode;
                  self.component = BaseLoader;
                  self.closable = false;
                  self.footer = false;
                  time = Date.now();
                  this.isProcessing = true;
                  this.name = instance.name;
                  delete instance.name;
                  const promises = [];
                  Object.keys(instance).forEach(secret =>
                    promises.push(
                      this.addSecret(instance[secret])
                        .then(data => {
                          if (data && data.secret_ref) {
                            this.certificatePayload.push({
                              name: secret,
                              ...data,
                            });
                          }
                        })
                        .catch(e => {
                          console.log(e);
                          this.showError(e);
                          this.$modals.close();
                        })
                    )
                  );
                  Promise.all(promises).then(() => {
                    this.createCertificate(this.certificatePayload)
                      .catch(e => {
                        console.log(e);
                        this.showError(e);
                        this.$modals.close();
                      })
                      .finally(() => {
                        this.certificatePayload = [];
                        const delay = Date.now() - time < 1000 ? 1000 : 0;
                        this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'secrets');
                        this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'containers');
                        setTimeout(() => {
                          self.component = null;
                          self.closable = true;
                          self.text = this.$t('sure.success');
                          self.footer = {
                            centered: true,
                            confirm: {
                              props: { title: this.$t('sure.close') },
                              on: { click: () => this.$modals.close() },
                            },
                          };
                        }, delay);
                      });
                  });
                },
              },
            },
          },
          cancel: {
            on: {
              click: () => {
                that.$modals.close();
              },
            },
          },
        });
      });
    },
    addSecret(payload) {
      return this.$store.dispatch('moduleStack/createSecret', payload).catch(e => {
        console.log(e);
        this.showError(e);
      });
    },
    createCertificate(payload) {
      const params = {
        secret_refs: payload,
        name: this.name,
        type: 'certificate',
      };
      return this.$store.dispatch('moduleStack/createContainer', params).catch(e => {
        console.log(e);
        this.showError(e);
      });
    },
    makeModal(props = {}) {
      this.$modals.open({
        name: 'AddModal',
        size: 'small',
        onOpen: inst => (this.modal = inst),
        onClose: () => (this.modal = null),
        onDismiss: () => (this.modal = null),
        ...props,
      });
    },
    showResModal(res, props = {}) {
      if (!this.modal) this.makeModal(props);
      Vue.set(this.modal, 'component', null);
      Vue.set(this.modal, 'closable', true);
      Vue.set(this.modal, 'text', res);
      Vue.set(this.modal, 'footer', {
        confirm: {
          on: {
            click: () => {
              this.$modals.close();
            },
          },
        },
      });
    },
  },
};
