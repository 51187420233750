<template>
  <div class="vps-detail">
    <transition name="fade" mode="out-in">
      <div v-if="isCurrentLoading" class="vps-detail__loader">
        <base-loader class="vps-detail__loader-item" />
      </div>
      <base-alert
        v-else-if="!isCurrentLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="vps-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <div v-else-if="tariff" class="vps-detail__inner">
        <div class="vps-detail__content">
          <!--            :state="state(tariff)"-->
          <!--            :status="tariff.status"-->
          <!--            :specs="specsObj" //Если будет, что засунуть в спецификацию, то вернем-->
          <layout-stack-title
            :title="tariff.name"
            :address="tariff.id"
            :description="tariff.description"
            class="vps-detail__head"
          >
            <!--            -->
            <v-popover
              v-if="isMobile && firewallid"
              slot="btn"
              placement="bottom"
              popover-class="vps-detail__popover"
            >
              <sidebar-button />
              <firewall-context-menu
                slot="popover"
                :tariff="tariff"
                :is-loading="isCurrentLoading"
                class="vps-detail__context-menu"
              />
            </v-popover>
          </layout-stack-title>
          <tabs :list="nav" class="medium-title">
            <template v-slot:item="{ item }">
              <router-link exact-active-class="active" :to="item.to">
                {{ item.title }}
              </router-link>
            </template>
          </tabs>
          <transition name="slide-fade">
            <router-view v-if="tariff" :tariff="tariff"></router-view>
          </transition>
        </div>
        <main-card v-if="!isMobile" class="vps-detail__aside">
          <firewall-context-menu
            v-if="firewallid"
            :tariff="tariff"
            :is-loading="isCurrentLoading || isLoading"
          />
          <base-loader v-else />
        </main-card>
      </div>
    </transition>
  </div>
</template>

<script>
import LayoutStackTitle from '@/components/LayoutTitle/LayoutStackTitle.vue';
import SidebarButton from '@/components/Buttons/SidebarButton.vue';
import MainCard from '@/components/MainCard/MainCard';
import FirewallContextMenu from '../pages/Main/components/FirewallContextMenu';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../mixins/index';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import tabs from '@/components/Tabs/Tabs';
export default {
  name: 'Firewall',
  components: {
    LayoutStackTitle,
    SidebarButton,
    MainCard,
    FirewallContextMenu,
    BaseAlert,
    tabs,
  },
  mixins: [storeMixin, providerChange],
  props: {
    firewallid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nav: [
        {
          title: this.$t('nav.firewall'),
          to: { name: 'FirewallView', params: { firewallid: this.firewallid } },
        },
      ],
      isCurrentLoading: false,
      isServerError: false,
      isMobile: true,
    };
  },
  computed: {
    tariff() {
      return this.$store.state.moduleStack.firewalls.find(x => x.id === this.firewallid);
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    specsObj() {
      const specs = this.tariff;

      let list = [];
      if (specs.name) list.push(specs.name);
      return {
        list,
      };
    },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
    this.newFetch();
  },
  methods: {
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
    // state(item) {
    //   const state = item.admin_state_up;
    //   return {
    //     color: state === true ? 'success' : 'error',
    //     key: state === true ? 'running' : 'stopped',
    //   };
    // },
  },
  // },
};
</script>

<i18n>
  {
    "ru": {
      "nav": {
        "firewall": "Правила"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.vps-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 2.5rem;
    }
  }

  &__context-menu {
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
