<template>
  <div class="port">
    <div class="l-col">
      <base-input
        v-model="formData.name"
        :label="$t('label.name')"
        :required="true"
        :placeholder="$t('label.placeholder')"
        type="text"
        size="medium"
        use-reactive-validation
        class="form__field--input"
        @input="onChange"
      />
    </div>
    <div class="l-col">
      <base-input
        v-model="formData.certificate.payload"
        type="textarea"
        :required="true"
        :label="$t('certificate.sert')"
        :pattern="pattern.certificate ? formData.certificate.payload : 'fail'"
        :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
        use-reactive-validation
        size="medium"
        :placeholder="$t('certificate.placeholder')"
        @input="onChange"
        @validate-input="onChange()"
      />
    </div>
    <div class="l-col">
      <base-input
        v-model="formData.private_key.payload"
        type="textarea"
        :label="$t('private_key.name')"
        :pattern="pattern.private_key ? formData.private_key.payload : 'fail'"
        :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
        use-reactive-validation
        :required="true"
        size="medium"
        :placeholder="$t('private_key.placeholder')"
        @input="onChange"
        @validate-input="onChange()"
      />
    </div>
    <div>
      <div class="l-col">
        <base-checkbox :model-value="checked" @change="onChecked">
          {{ $t('addSert') }}
        </base-checkbox>
      </div>
      <div v-if="checked" class="l-col">
        <base-input
          v-model="formData.intermediates.payload"
          type="textarea"
          :label="$t('certificate.inter')"
          :pattern="pattern.intermediates ? formData.intermediates.payload : 'fail'"
          :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
          :required="true"
          size="medium"
          :placeholder="$t('certificate.placeholder')"
          @input="onChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseSelect from '@/components/Select/BaseSelect';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
import BaseRadio from '@/components/Checkboxes/BaseRadio.vue';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox';
export default {
  name: 'AddContainer',
  components: { BaseInput, BaseCheckbox },
  mixins: [setFocus],
  props: {
    instance: {
      type: Object,
      default: () => {},
    },
    router: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formData: {
        name: '',
        certificate: {
          name: 'certificate',
          payload: '',
          payload_content_type: 'text/plain',
        },
        private_key: {
          name: 'private_key',
          payload: '',
          payload_content_type: 'text/plain',
        },
        intermediates: {
          name: 'intermediates',
          payload: '',
          payload_content_type: 'text/plain',
        },
      },
      name: '',
      checked: false,
      pattern: {
        certificate: false,
        private_key: false,
        intermediates: false,
      },
      certificate: '',
      private_key: '',
      intermediates: '',
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('error') },
      },
    };
  },
  computed: {
    isReady() {
      return (
        this.formData.name &&
        this.pattern.private_key &&
        this.pattern.certificate &&
        ((this.checked && this.pattern.intermediates) || !this.checked)
      );
    },
  },
  watch: {
    'formData.certificate.payload': function (event) {
      const array = event.split('\n');
      if (
        array &&
        array.at(0) &&
        array.at(-1) &&
        array.at(0) === '-----BEGIN CERTIFICATE-----' &&
        array.at(-1) === '-----END CERTIFICATE-----'
      ) {
        console.log('tested');
        this.pattern.certificate = true;
      } else this.pattern.certificate = false;
      // console.log(event);
    },
    'formData.intermediates.payload': function (event) {
      const array = event.split('\n');
      if (
        array &&
        array.at(0) &&
        array.at(-1) &&
        array.at(0) === '-----BEGIN CERTIFICATE-----' &&
        array.at(-1) === '-----END CERTIFICATE-----'
      ) {
        console.log('tested');
        this.pattern.intermediates = true;
      } else this.pattern.intermediates = false;
      // console.log(event);
    },
    'formData.private_key.payload': function (event) {
      const array = event.split('\n');
      array.at(0);
      array.at(-1);
      if (
        array &&
        array.at(0) &&
        array.at(-1) &&
        array.at(0).includes('PRIVATE KEY') &&
        array.at(0).startsWith('-----') &&
        array.at(0).endsWith('-----') &&
        array.at(-1).includes('PRIVATE KEY') &&
        array.at(-1).startsWith('-----') &&
        array.at(-1).endsWith('-----')
      ) {
        console.log('tested');
        this.pattern.private_key = true;
      } else this.pattern.private_key = false;
      // console.log(event);
    },
  },
  mounted() {
    this.$emit('notready');
  },

  beforeDestroy() {},
  methods: {
    onChecked(val) {
      this.checked = val;
      if (this.checked && this.pattern.intermediates) this.$emit('ready');
      else if (this.isReady) this.$emit('ready');
      else this.$emit('notready');
    },
    getFormData() {},
    onChange(event) {
      if (this.isReady) {
        this.$emit('ready');
        const readyDate = Object.assign({}, this.formData);
        if (!this.checked) delete readyDate.intermediates;
        this.$emit('change', readyDate);
      } else this.$emit('notready');
      // console.log(event);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "label": {
      "name": "Имя",
      "placeholder": "Введите имя сертификата"
    },
    "error": "Не верный формат",
    "addSert": "Добавить промежуточный сертификат",
    "certificate": {
      "sert": "Сертификат",
      "placeholder": "Сертификат с тэгами -----BEGIN CERTIFICATE----- и -----END CERTIFICATE-----",
      "inter": "Промежуточный сертификат"
    },
    "tooShort": "Введите 0 цифр | Введите 1 цифру | Введите {count} цифры | Введите {count} цифр",
    "private_key": {
      "name": "Приватный ключ",
      "placeholder": "Приватный ключ с тэгами -----BEGIN PRIVATE KEY----- и -----END PRIVATE KEY-----"

    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.port {
  text-overflow: unset;
  overflow: visible;
  &__config {
    display: flex;
    justify-content: space-around;

    &-item {
    }
  }

  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1.5rem 1.5rem;
&-input {
  padding: 1.5rem 1.5rem;
}
  }
}
</style>
